.rt-th.rt-resizable-header{
    width: 160px !important;    
}

.rt-tbody .rt-td{
    width: 160px !important;    
}

.rt-tbody {
    overflow: visible !important;
}